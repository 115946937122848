import React from 'react'
import Card from 'components/ui/Card'

const getTutorials = () => {
 const cardItems = []
 for (let i = 0; i < 9; i++) {
  cardItems.push(
   <Card
    imgUrl='https://uploads.toptal.io/blog/image/125395/toptal-blog-image-1518187252525-03f6db7b1c131066061024c236c7e3ff.png'
    title='Angular Basics'
    subheader='An introduction to Angular'
    description='Learn about Angular and how to build beginner apps'
    actionButton={{
     title: 'Enroll',
     onClick: () => { alert('hello world!') }
    }}
   />
  )
 }
 return cardItems
}

export default () => (
 <>
  {getTutorials()}
 </>
)