import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MenuToolbar from "../components/MenuToolbar"
import Tutorials from './Tutorials'

const LessonsPage = () => (
 <>
  <SEO title="Search" keywords={[`Angular`, `React`, `JavaScript`]} />
  <MenuToolbar />
  <Layout>
   <div style={{ maxWidth: `100%`, marginBottom: `1.45rem`, marginTop: '50px' }}>
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
     <Tutorials />
    </div>

   </div>
  </Layout >
 </>
)

export default LessonsPage
