import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import theme from './Card.module.scss'
import Button from '../Button'

const Card = ({
  title,
  subheader,
  description,
  imgUrl,
  buttonTitle,
  onClick,
  className
}) => (
    <div className={cn(theme.Card, className)}>
      {imgUrl && (
        <img className={theme.Card_Image} src={imgUrl} alt='card' />
      )}
      <div className={theme.Card_ContentContainer}>
        <div className={theme.Card_Title}>{title}</div>
        {subheader && (
          <div className={theme.Card_SubHeader}>{subheader}</div>
        )}
        {description && (
          <p className={theme.Card_Description}>{description}</p>
        )}
        {buttonTitle && (
          <Button title={buttonTitle} onPress={onClick} />
        )}
      </div>
    </div>
  )

Card.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  description: PropTypes.string,
  imgUrl: PropTypes.string,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
}

export default Card
